<script>
import PageHeader from "@/components/page-header.vue";
import RentalList from "@/views/pages/orders/rental_wagons/components/List.vue"

export default {
  name: "Index",
  components: {
    PageHeader,
    RentalList
  },
  data() {
    return {
      title: 'Rental Wagons',
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Rental Wagons",
          active: true,
        },
      ],
    }
  }
}
</script>

<template>
  <PageHeader :title="title" :items="items"/>

  <RentalList />
</template>

<style scoped>

</style>