<script>
import Table from "@/components/tables/table.vue";

export default {
  name: "List",
  components: {Table},
  data() {
    return {
      table_headers: [
        {field: 'order_number', label: 'Order Number', align: 'center'},
        {field: 'lot_number', label: 'Lot Number', align: 'center'},
        {field: 'total_agreed_rate', label: 'Agreed Rate', align: 'center'},
        {
          field: 'position',
          label: 'Position',
          align: 'center',
          searchType: 'select',
          searchOptions: [
            {label: 'All', value: ''},
            {label: 'Multi modal', value: 'multi_modal'},
            {label: 'Block train', value: 'block_train'},
            {label: 'Rail forwarder', value: 'rail_forwarder'},
          ],
          excel_data: (application) => {
            return application.order.position
          },
        },
        {
          field: 'type',
          label: 'Order Type',
          align: 'center',
          searchType: 'select',
          searchOptions: [
            {label: 'All', value: ''},
            {label: 'Import', value: 'import'},
            {label: 'Export', value: 'export'},
            {label: 'Transit', value: 'transit'},
          ],
          excel_data: (application) => {
            return application.order.type
          },
        },
        {field: 'quantity', label: 'Quantity', align: 'center'},
        {field: 'company', label: 'Company', align: 'center'},
        {field: 'date', label: 'Date', align: 'center'},
        {field: 'comment', label: 'Comment', align: 'center'},
        {field: 'user', label: 'Manager', align: 'center'},
      ]
    }
  }
}
</script>

<template>
  <Table searchable name="Rental Wagon Orders" :headers="table_headers" url="/wagon_order/list_rented/">
    <template #top-right>
      <router-link :to="{name: 'order_rental_wagons_create'}">
        <b-button variant="success">Create Rental Order</b-button>
      </router-link>
    </template>

    <template v-slot:order_number="{row: item}">
      <router-link :to="{name: 'orders_wagon_detail', params: {
        id: item.order.order_number
      }}">
        <span class="badge badge-soft-secondary fs-12">
          {{ item.order.order_number }}
        </span>
      </router-link>
    </template>
    <template v-slot:lot_number="{row: item}">
      {{ item.order.lot_number }}
    </template>
    <template v-slot:total_agreed_rate="{row: item}">
      {{
        parseFloat((item.order.total_agreed_rate || 0).toString()).toLocaleString(undefined, {
          minimumFractionDigits: 2, maximumFractionDigits: 2
        })
      }}
    </template>
    <template v-slot:company="{row: item}">
      <router-link :to="{
        name: 'customer_profile', params: {
          slug: item.order.company.slug
        }
      }" v-if="item.order.company">
        {{ item.order.company.name }}
      </router-link>
    </template>
    <template v-slot:position="{row: item}">
      {{ item.order.position }}
    </template>
    <template v-slot:type="{row: item}">
      {{ item.order.type }}
    </template>
    <template v-slot:date="{row: item}">
      {{ item.order.date }}
    </template>
    <template v-slot:comment="{row: item}">
      {{ item.order.comment }}
    </template>
    <template v-slot:user="{row: item}">
      <router-link :to="{
        name: 'user_profile', params: {
          slug: item.order.user.slug
        }
      }" v-if="item.order.user && item.order.user.slug">
        {{ item.order.user.username }}
      </router-link>
    </template>
  </Table>
</template>

<style scoped>

</style>